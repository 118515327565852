.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 14px 24px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 64px;
    transition: all 0.3s;
}
.white {
    background-color: #ffffff;
}
.white:hover {
    background-color: var(--neutral-dark-hover);
}
.white:active {
    background-color: var(--neutral-dark-active);
    color: #000000;
}
.primary {
    background-color: var(--main-color);
    color: #000000;
}
.primary:hover {
    background-color: var(--main-color-hover);
    color: #000000;
}
.primary:active {
    background-color: var(--main-color-active);
    color: #000000;
}

.primary[disabled] {
    background: #ff7f47;
    color: #cc6639;
    pointer-events: none;
}

.neutral-dark {
    background-color: var(--neutral-dark);
    color: #000000;
}
.neutral-dark:hover {
    background-color: var(--neutral-dark-hover);
    color: #000000;
}
.neutral-dark:active {
    background-color: var(--neutral-dark-active);
    color: #000000;
}

.neutral-light {
    background-color: var(--neutral-light);
    color: #000000;
}
.neutral-light:hover {
    background-color: var(--neutral-light-hover);
    color: #000000;
}
.neutral-light:active {
    background-color: var(--neutral-light-active);
    color: #000000;
}

.dark {
    background-color: var(--dark-color);
    color: var(--dark-text-color);
}
.dark:hover {
    background-color: var(--dark-color-hover);
}
.dark:active {
    background-color: var(--dark-color-active);
}

.subdark {
    background-color: var(--sub-dark);
    color: var(--dark-text-color);
}
.subdark:hover {
    background-color: var(--sub-dark-hover);
}
.subdark:active {
    background-color: var(--sub-dark-active);
}

.fullWidth {
    width: 100%;
}

.iconLeft {
    margin-right: 8px;
}

.iconRight {
    margin-left: 8px;
}
