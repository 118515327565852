.sidebar-menu-verification {
    display: none;
    padding: 32px;
    min-width: 300px;
    width: 33%;
    background-color: rgba(30, 39, 55, 1);
}
.header {
    display: flex;
    justify-content: center;
}
.logo {
    position: absolute;
    top: 32px;
    margin-bottom: 32px;
}
.title {
    color: rgba(237, 233, 227, 1);
    font-family: Aeonik;
    font-size: 36px;
    line-height: 41px;
    text-align: left;
    margin-bottom: 16px;
}
.subtitle {
    font-family: Aeonik;
    color: rgba(237, 233, 227, 1);
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin-bottom: 16px;
}
.stage {
    color: rgba(237, 233, 227, 1);
    font-family: Aeonik;
    font-size: 21px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;

    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}
.icon-stage {
    color: rgba(30, 39, 55, 1);
    margin-right: 11px;
    text-decoration: none;
    align-content: center;
    text-align: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: rgba(237, 233, 227, 1);
}

.icon-stage.active {
    background: rgba(255, 127, 71, 1);
    color: rgba(237, 233, 227, 1);
}

.icon-stage.completed {
    background: rgba(17, 22, 31, 1);
    color: rgba(237, 233, 227, 1);
}

.icon-stage.completed svg {
    font-size: 24px;
}
@media (min-width: 1024px) {
    .sidebar-menu-verification {
        display: flex;
        flex-direction: column;
        padding: 32px;
        padding-top: 280px;
        min-width: 300px;
        width: 33%;
        background-color: rgba(30, 39, 55, 1);
    }

    .stage {
        margin-bottom: 32px;
    }

    .subtitle {
        margin-bottom: 80px;
    }
}
