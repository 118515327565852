.card {
    position: relative;
    margin-bottom: 64px;
}

.content {
    background: #fff;
    border: 1px solid #000000;
}

.imageWrapper {
    padding: 47px 25px;
    height: 591px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageWrapper img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}

.tags {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
}
.tags > * + * {
    margin-left: 8px;
}
.title {
    margin-top: 24px;
    font-size: 27px;
    line-height: 31px;
}

.favourite {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}

.favouriteActive circle,
.favouriteActive,
.favourite:hover circle,
.favourite:hover {
    fill: #fbe1df;
    stroke: #fbe1df;
}

.favouriteActive path {
    fill: #eb6b5e;
    stroke: #eb6b5e;
}

.status,
.views {
    padding: 8px 13px;
    font-size: 12px;
    line-height: 12px;
    color: #000;
    border-radius: 64px;
    background: var(--main-color);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    font-family: 'GT Pressura', monospace;
    text-transform: uppercase;
}
.views {
    background: #ede9e3;
}

.prebid {
    background: #1E2737;
    color: #fff;
}
.sold {
    background: #d4cfc7;
}
.live:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #000;
    margin-right: 4px;
    animation: pulsing 1s infinite;
}

.bidInfo {
    border-top: 1px solid #000;
    padding: 16px;
    display: flex;
}
.bidColumn:not(:first-child) {
    margin-left: 40px;
}

@media (max-width: 1023px) {
    .imageWrapper {
        padding: 36px 20px;
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bidColumn:not(:first-child) {
        margin-left: 30px;
    }
    .card {
        margin-bottom: 32px;
    }
}

/* make global animate ?  */
@keyframes pulsing {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
