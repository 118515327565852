.mainModal {
    width: 32% !important;
    background-color: #ede9e3;
    padding: 32px;
}

.link {
    color: #000000;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Aeonik';
}

.loginBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn {
    border-radius: 64px;
    padding: 16px 24px;
    background-color: var(--main-color);
    color: #1e2737;
    font-size: 16px;
    line-height: 19px;
    border: none;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 32px;
}
.btn:hover {
    opacity: 0.9;
}
.btn:active {
    background: linear-gradient(0deg, #ff7f47, #ff7f47),
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.disabled {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
}
.modalOverlay {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContent {
    background-color: #ede9e3;
    color: #000000;
    padding: 48px 16px;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 451px;
    transition: all 0.3s ease;
    h4 {
        font-size: 36px;
        line-height: 41px;
        font-weight: 400;
        margin-bottom: 8px;
    }
}
.modalFormBox {
    margin-top: 32px;
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
}
.modalContent.show {
    transform: translateY(0);
}
.forgotWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.footerText {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 22px;
    font-family: 'GT Pressura', monospace;
}
.steps {
    margin-top: 10px;
    font-weight: 400;
    color: #000000;
    font-family: 'GT Pressura', monospace;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    justify-content: center;
}
.linkForgotBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 32px;
}
@media (min-width: 1024px) {
    .modalContent {
        padding: 32px;
        width: 33%;
        min-width: 451px;
        height: 570px;
        border-radius: 16px;
    }
    .footerText {
        margin-top: 64px;
    }
}
