.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #000;
    padding-bottom: 16px;
    margin-bottom: 32px;
}

.row:hover {
    cursor: pointer;
}
