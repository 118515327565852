.container {
    display: flex;
    align-items: center;
}
.status {
    padding: 16px 24px 16px 24px;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    border-radius: 64px;
    background: var(--main-color);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
}

.prebid {
    background: #1E2737;
    color: #fff;
}
.closed {
    background: #d4cfc7;
}
.live:before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: #000;
    margin-right: 8px;
    animation: pulsing 1s infinite;
}

.status + .times,
.times + .times {
    margin-left: 40px;
}

.timeLabel {
    font-family: 'GT Pressura', sans-serif;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    white-space: nowrap;
}
.timeContent {
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
}

@media (max-width: 1023px) {
    .status {
        width: 100%;
        order: 9;
        justify-content: center;
        margin-top: 40px;
    }
    .container {
        flex-wrap: wrap;
    }
    .status + .times {
        margin-left: 0;
    }
}

@keyframes pulsing {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
