.termsWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    padding-bottom: 16px;
}
.closeButtonTerms {
    width: 36px;
    height: 36px;
    background-color: #212228;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 32px;
    cursor: pointer;
}
.termsTitleImageBox {
    display: flex;
    width: 100%;
    flex-direction: column;

    h3 {
        font-size: 36px;
        line-height: 41px;
    }
}

.termsTextSection {
    margin-top: 40px;
    padding-bottom: 16px;
    margin-bottom: 0px;
    position: relative;
    font-size: 16px;
    line-height: 19px;
}

.termsTextSection::-webkit-scrollbar {
    width: 0;
}

.termsImageSection {
    display: none;
}

.closeIconBox {
    position: absolute;
    right: 16px;
    top: 16px;
    height: 36px;
}

.closeIconBox svg {
    width: 36px;
    height: 36px;
}
.termsTextGradient {
    display: none;
}
@media (min-width: 1024px) {
    .termsWrapper {
        max-height: calc(100% - 16px);
        flex-direction: row;
        padding-top: 0px;
        padding-right: 32px;
        padding-bottom: 0;
    }
    .termsImageSection {
        display: block;
    }
    .termsTextSection {
        overflow-y: scroll;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0;
    }

    .termsTitleImageBox {
        margin-top: 90px;
        display: flex;
        width: 80%;

        h3 {
            font-size: 32px;
            line-height: 30px;
        }
    }
    .termsTextGradient {
        display: block;
        content: '';
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        height: 180px;
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
        pointer-events: none;
    }
}
@media (min-width: 1440px) {
    .termsWrapper {
        flex-direction: row;
        /* padding-top: 74px; */
        padding-right: 56px;
    }
    .termsTitleImageBox {
        h3 {
            font-size: 47px;
            line-height: 54px;
        }
    }
}
