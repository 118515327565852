.fileButton {
    border-radius: 64px;
    padding: 16px 24px;
    color: rgba(30, 39, 55, 1);
    font-size: 16px;
    line-height: 19px;
    border: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    transition: all 0.2s;
    text-align: center;
}

.fileButton input[type='file'] {
    display: none;
}

.fileButton:hover {
    opacity: 0.9;
    cursor: pointer;
}
