.wrapper {
    position: absolute;
    right: 0;
    top: -92px;
}

.sortDropdown {
    position: relative;
}

.sortCurrent {
    background: var(--neutral-dark);
    color: #000;
    padding: 16px 24px;
    border-radius: 48px;
    font-size: 16px;
    line-height: 19px;
    transition: background-color 0.25s;
    text-align: center;
}

.sortCurrent:hover {
    cursor: pointer;
    background: var(--neutral-dark-hover);
}

.sortOptions {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 331px;
    padding: 16px;
    background-color: var(--neutral-dark);
    border-radius: 8px;
    z-index:1;
}

.sortOption {
    padding: 11px 16px;
}
.sortOption:hover {
}

.sortOptionLabel {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.sortOptionLabel input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.sortOptionCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #ede9e3;
    border-radius: 50%;
}

.sortOptionCheckmark:after {
    content: '';
    position: absolute;
    display: none;
}

.sortOptionLabel input:checked ~ .sortOptionCheckmark:after {
    display: block;
}

.sortOptionCheckmark:after {
    top: 8px;
    left: 8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #000;
}

@media (max-width: 1023px) {
    .wrapper {
        position: static;
        margin-bottom: 40px;
        margin-top:14px;
    }
    .sortOptions {
        width:100%;
    }
}
