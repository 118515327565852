.link {
    color: var(--main-color);
}
.link:hover,
.link:focus {
    color: var(--main-color-hover);
}
.nav {
    background: transparent;
    height: 95px;
}
.logo, .logo a {
    color: var(--main-color);
}
.nav .link {
    font-size: 27px;
}
.scrolled {
    background: var(--sub-color);
}

.footer {
    background: var(--sub-color);
    padding-bottom: 40px;
}
.footerLogo {
    display: flex;
    justify-content: center;
    padding: 200px 0;
    color: var(--main-color);
}
.footerLogo svg {
    width: 678px;
    height: 135px;
}

.footer.extraBottomPadding {
    padding-bottom: 180px;
}

@media (max-width: 1023px) {
    .footerLogo {
        padding: 50px 16px 100px;
    }
    .nav svg {
        width: 161px;
    }
    .nav {
        height: 80px;
    }
    .nav .link {
        font-size: 16px;
    }
}

.modal-opened {
    position: fixed;
    overflow: hidden;
    width: 100%;
    padding-right: 15px;
}
