.sidebar-menu-user-profile {
    padding: 32px 24px;
    min-width: 300px;
    width: 100%;
    background-color: #d4cfc7;
}
.nav {
    background-color: transparent;
    position: absolute;
    width: 100%;
    .navBox {
        width: 100%;
        height: 48px;
        justify-content: space-between;
    }
    .navPhoto {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
.headerMobile {
    height: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.back {
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    background-color: #ede9e3;
    background-image: url('../../../../public/black_back_arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    outline: none;
    box-shadow: none;
}
.brandImage {
    height: 32px;
}
.title {
    color: #1e2737;
    font-family: Aeonik;
    font-size: 27px;
    line-height: 30px;
}

.stage {
    font-family: Aeonik;
    font-size: 21px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;

    display: flex;
    align-items: center;
    margin-bottom: 32px;
}
.stage.active {
    opacity: 0;
}
.stageTitle.active {
    color: #000000;
    opacity: 1;
}
.stageTitle {
    opacity: 0.4;
}
.icon-stage {
    margin-right: 11px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
}

.icon-stage.active {
    background: rgba(255, 127, 71, 1);
}
.footerMobile {
    color: #000000;
    font-size: 21px;
    line-height: 25px;
}
.home {
    color: #1e2737;
    font-size: 16px;
    line-height: 19px;
}
