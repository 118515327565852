.checkboxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all 0.3s;
    font-size: 16px;
}
.checkboxWrapper:has(input:checked) {
    color: var(--main-color);
}
.checkboxWrapper input {
    width: 31px;
    height: 31px;
    background: #1e2737;
    border: 0;
    margin-left: 16px;
    outline: none;
    margin-top:0;
}

.checkboxWrapper input:checked {
    background-image: url('data:image/svg+xml,<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 6.5L6 11.5L16.5 1" stroke="%23FF7F47" stroke-width="2"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    outline: none;
    box-shadow: none;
    background-color: #1e2737;
}
.checkboxWrapper input:focus {
    border: 0;
    outline: none;
    box-shadow: none;
}

.disabled {
    color: #1e2737;
}
