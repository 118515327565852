.americasWrapper {
    gap: 60px;
    height: 100%;
}
.skrollBox {
    padding-top: 90px;
    height: 100%;
}

.americasTitleImageBox {
    display: flex;

    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 16px;
}

.americasTitleImageBox h3 {
    font-size: 32px;
    line-height: 34px;
}

.americasImageSection {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    max-width: 290px;
    max-height: 372px;
}

.americasImageSection img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.americasTextSection {
    margin-top: 40px;
    font-size: 16px;
    line-height: 19px;
    padding-right: 16px;
    padding-bottom: 16px;
}

.closeIcon {
    display: flex;
}
.closeIconBox {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 38px;
    height: 38px;
    background-color: #d4cfc7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;

    cursor: pointer;
}
.emptyBox {
    height: 0;
}
.closeIcon img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

@media (min-width: 1024px) {
    .emptyBox {
        height: 90px;
    }
    .americasWrapper {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

    .americasTitleImageBox {
        justify-content: flex-start;
        flex-direction: column;
        flex-shrink: 0;
        /* margin-top: 32px; */
        width: 40%;
        h3 {
            font-size: 32px;
            line-height: 34px;
        }
    }

    .americasImageSection {
        margin: 0;
        margin-top: 16px;
    }

    .americasTextSection {
        margin-top: 0px;
        padding-right: 56px;
        padding-bottom: 0px;
        font-size: 16px;
        line-height: 19px;
        flex-grow: 1;
        overflow-y: auto;
        max-height: calc(100% - 3px);
    }
    .americasTextSection::-webkit-scrollbar {
        width: 0;
    }
    .americasTextGradient {
        display: block;
        position: sticky;
        bottom: -48px;
        left: 0;
        right: 0;
        height: 100px;
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
        pointer-events: none;
    }
}

@media (min-width: 1440px) {
    .closeIconBox {
        width: 48px;
        height: 48px;
    }

    .americasTitleImageBox h3 {
        font-size: 47px;
        line-height: 54px;
    }
}
