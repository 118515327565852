.progressBar {
    position: relative;
    width: 100px;
    height: 100px;
}

.circle {
    height: 100%;
    right: 0;
    position: absolute;
    border: solid 5px  #D4CFC7;
    border-top-color:  #fff;
    border-radius: 50%;
}


.border{
    width: 100%;
    transform: rotate(135deg);
    animation: spin 1.3s steps(2) .2s infinite;
    -webkit-animation: spin 1.3s linear infinite;

}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
