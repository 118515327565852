.tagsContainer {
    font-size: 12px;
    font-family: 'GT Pressura', monospace;
    display: block;
    text-transform: uppercase;
    margin-bottom: 34px;
}

.tagsContainer span {
    margin-right: 14px;
    position: relative;
    font-family: inherit;
}
.tagsContainer span:not(:last-child):after {
    content: '';
    width: 3px;
    height: 3px;
    display: inline-block;
    background: #000;
    right: -8px;
    top: -1px;
    position: absolute;
    margin: auto;
    bottom: 0;
    border-radius: 4px;
}

@media (max-width: 1023px) {
    .tagsContainer {
        position: absolute;
        top: 100px;
        margin-bottom: 0;
        left: 16px;
        right: 16px;
    }
}
