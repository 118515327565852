body * {
    margin: 0;
    font-family:
        Aeonik,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
main {
    background: #ede9e3;
}

h1 {
    font-size: 80px;
    line-height: 100%;
    letter-spacing: -0.02em;
}

h3 {
    font-size: 47px;
    line-height: 54px;
}

p {
    font-size: 16px;
    line-height: 19px;
}

a {
    color: #000;
    text-decoration: underline;
}

@media (max-width: 1023px) {
    h1 {
        font-size: 36px;
    }
    h3 {
        font-size: 36px;
        line-height: 41px;
    }
}

@media (min-width: 1440px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1437px;
    }
}
