.modalFormBox {
    margin-top: 32px;
}
.newPassword h4 {
    margin-top: 32px;
}
.newPassword {
    margin-top: 32px;
}
.NewPassWrapper {
    height: 100%;
    form {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
}
@media (min-width: 1024px) {
    .NewPassWrapper {
        min-height: 470px;
        form {
            min-height: 470px;
        }
    }
    .newPassword {
        margin-top: 0;
    }
}
@media (min-width: 1440px) {
    .NewPassWrapper {
        min-height: 570px;
        form {
            min-height: 570px;
        }
    }
}
