.link {
    color: #000000;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Aeonik';
}

.closeIcon {
    display: flex;
    justify-content: flex-end;
}
.btnLogin {
    border-radius: 64px;
    padding: 16px 24px;
    background-color: var(--main-color);
    color: #1e2737;
    font-size: 16px;
    line-height: 19px;
    border: none;
    width: 100%;
    margin-bottom: 16px;
    transition: background-color 0.3s ease;
}
/* стили кнопок */

.btnLogin:hover {
    opacity: 0.9;
}
.btnLogin:active {
    background: linear-gradient(0deg, #ff7f47, #ff7f47),
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.disabled {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
}
.linkForgotBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 32px;
}
.loginBox {
    display: flex;
    flex-direction: column;
}
.loginWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    form {
        height: 100%;
    }
}
.wrapperLogin h4 {
    align-self: start;
    font-size: 36px;
    line-height: 41px;
    font-weight: 400;
    margin-bottom: 32px;
    margin-top: 0;
}
.loginBox .link {
    align-self: center;
}
.wrapperLogin {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.linkForgotBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 32px;
    margin-top: 8px;
}
.loginContent {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.footerText {
    font-size: 12px;
    line-height: 12px;
    display: flex;
    justify-content: center;
    margin-top: 48px;
    margin-bottom: 22px;
    font-family: 'GT Pressura', monospace;
}
@media (min-width: 1024px) {
    .loginContent {
        justify-content: space-between;
    }
    .closeIcon {
        display: none;
    }
    .modalFormBox {
        margin-top: 0px;
    }

    .wrapperLogin h4 {
        margin-top: 8px;
        margin-bottom: 16px;
    }
    .linkForgotBox {
        margin-bottom: 32px;
    }
    .wrapperLogin svg {
        display: none;
    }
    .footerText {
        margin-top: 50px;
        margin-bottom: 22px;
    }
}
@media (min-width: 1440px) {
    .wrapperLogin h4 {
        margin-bottom: 32px;
    }
}
