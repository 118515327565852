.btnVerificationNext {
    width: 100%;
    background-color: #d4cfc7;
    border-radius: 64px;
    padding: 16px 24px;
    color: rgba(30, 39, 55, 1);
    font-size: 16px;
    line-height: 19px;
    border: none;
}
.activeButton {
    background-color: #ff7f47;
}
.verificationHeader {
    z-index: 100;
    display: flex;
    align-items: center;
    position: fixed;
    height: 80px;
    width: 100%;
    padding: 16px;
    background-color: #1e2737;
    color: #ede9e3;
    font-size: 21px;
    line-height: 25px;
}
.btnVerificationNext:hover {
    opacity: 0.9;
}
.btnVerificationNext:active {
    background: linear-gradient(0deg, #ff7f47, #ff7f47),
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.identityBox {
    width: 100%;
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.identityBoxHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
}
.titleVerification {
    margin-bottom: 16px;
    font-size: 36px;
    line-height: 41px;
}
.identityContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 112px 16px 32px 16px;
    min-height: 100vh;
    background-color: rgba(237, 233, 227, 1);
}
.headerStage {
    color: rgba(30, 39, 55, 1);
    margin-right: 11px;
    text-decoration: none;
    align-content: center;
    text-align: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: #ff7f47;
}
.verificationButtonWrapperThird {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    button {
        width: 100%;
    }
}
.downloadBox {
    min-height: 345px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 78px;
    align-items: center;
}
.dndBox {
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    border: 2px #11161f dashed;
    min-height: 235px;
    margin-bottom: 16px;
    position: relative;
    transition:
        background-color 0.3s ease,
        border-color 0.3s ease;
}
.dndBox:hover {
    background-color: rgba(73, 93, 133, 0.2);

    border-color: #1e2737;
}
.fileInput {
    display: none;
}

.dndLabel {
    font-size: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chooseFile {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 3px;
}
.formatInfo {
    display: none;
}
.verificationButtonWrapperThird {
    bottom: 0;
    padding: 16px;
    width: 100%;
    left: 0;
    position: fixed;
    display: flex;
    margin-top: 32px;
}
@media (min-width: 1024px) {
    .verificationButtonWrapperThird {
        margin-top: 22px;
        width: auto;
        position: static;
        padding: 0;
        button {
            width: 94px;
        }
    }
    .identityBoxHeader {
        margin-bottom: 48px;
    }
    .dndBox {
        margin-bottom: 0;
    }
    .formatInfo {
        display: block;
        margin-top: 14px;
        margin-bottom: 44px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .downloadBox {
        min-height: 345px;
        margin-bottom: 0;
        flex-direction: column;
    }
    .identityBox {
        width: 67%;
        height: 100%;
        /* max-height: 475px; */
        justify-content: center;
    }
    .titleVerification {
        margin-bottom: 16px;
        font-size: 36px;
        line-height: 41px;
    }
    .identityContainer {
        margin-top: 0px;
        padding: 237px 0 123px 0;
    }
    .btnVerificationNext {
        width: 94px;
    }
    .verificationHeader {
        display: none;
    }
}
