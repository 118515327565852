.sidebar-menu-mobile {
    padding: 32px 16px;
    display: flex;
    flex-direction: column;

    background-color: rgba(30, 39, 55, 1);
}
.header-mobile {
    display: flex;
    justify-content: center;
}
.logo-mobile {
    margin-bottom: 199px;
    width: 120px;
    svg {
        width: inherit;
    }
}
.title-mobile {
    color: rgba(237, 233, 227, 1);
    font-family: Aeonik;
    font-size: 36px;
    line-height: 41px;
    text-align: left;
    margin-bottom: 16px;
}
.subtitle-mobile {
    font-family: Aeonik;
    color: rgba(237, 233, 227, 1);
    font-size: 16px;
    line-height: 19px;
    text-align: left;

    text-align: center;
}
.button-sidebar-mobile {
    border-radius: 64px;
    padding: 16px 24px;
    background-color: var(--main-color);
    color: #1e2737;
    font-size: 16px;
    line-height: 19px;
    border: none;
    width: 100%;
}

.header-mobile {
    flex-shrink: 0;
}

.body-mobile {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.content {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.button-container {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}

.button-sidebar-mobile {
    width: 100%;
}
