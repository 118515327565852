.rowWrapper {
    height: 100%;
}

.container {
    background: #11161f;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 35px;
    position: relative;
}
@media (min-width: 1023px) {
    .container:after {
        content: '';
        width: 100%;
        background: #11161f;
        height: 100%;
        position: absolute;
        right: -100%;
        pointer-events: none;
    }
}

.amount {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    flex: 1;
    font-size: 80px;
    color: var(--main-color);
}

.actions {
    max-width: 80%;
    margin: auto auto 0;
    text-align: center;
}
.actions button {
    min-width: 254px;
    margin-bottom: 24px;
}

.agreeText,
.agreeText a {
    color: #ede9e3;
    font-size: 16px;
    line-height: 19px;
}
.agreeText a {
    text-decoration: underline;
}

.disabled .amount,
.disabled .agreeText,
.disabled .agreeText a {
    opacity: 0.4;
}

@media (max-width: 1023px) {
    .container {
        padding-left: 21px;
        padding-right: 21px;
        padding-bottom: 24px;
        padding-top: 100px;
        border-radius: 16px;
    }

    .container button {
        width: 100%;
    }
    .actions {
        max-width: unset;
    }
    .amount {
        margin-bottom: 70px;
        font-size: 61px;
        line-height: 70px;
    }
}
