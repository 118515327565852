.bottomBar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--sub-color);
    color: #ede9e3;
    transition: height 0.5s ease-in-out;
    overflow: hidden;
    height: 138px; /* Высота по умолчанию */
    z-index: 1033;
}

.expanded {
    height: 75vh;
    max-height: 682px; /* Высота при раскрытии */
}

.bottomBarContent {
    height: 100%;
    display: flex;
    align-items: center;
    transition:
        opacity 0.5s ease-in-out,
        visibility 0.5s ease-in-out;
}

.hidden {
    height: 0;
    opacity: 0;
    visibility: hidden;
}

.expandedContent {
    display: none;
    height: 100%;
}

.expanded .expandedContent {
    display: block;
}

.contentLeft {
    padding: 10px;
    display: flex;
}
@media (min-width: 1024px) {
    .desktopHeight100 {
        height: 100%;
    }
}

.buttonsRight {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding: 10px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1032;
    opacity: 0;
    visibility: hidden;
    transition:
        opacity 0.5s ease-in-out,
        visibility 0.5s ease-in-out;
}

.visible {
    opacity: 1;
    visibility: visible;
}
.bidValueColumn + .bidValueColumn {
    margin-left: 48px;
}
.bidValue {
    font-size: 47px;
    line-height: 54px;
}

.winnerText {
    font-size: 47px;
    color: var(--main-color);
    white-space: nowrap;
}
.close {
    display: none;
}
@media (max-width: 1023px) {
    .close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background: #11161f;
        border-radius: 36px;
        margin-left: auto;
        z-index: 99;
        margin-top: 16px;
        margin-right: 16px;
    }

    .bottomBar {
        height: 170px;
    }
    .expanded {
        height: 100dvh;
        max-height: unset;
    }
    .bidValue {
        font-size: 27px;
        line-height: 30px;
    }
    .buttonsRight {
        justify-content: center;
    }
    .contentLeft {
        justify-content: center;
    }
    .expandedContent {
        overflow-y: auto;
        padding-bottom: 40px;
    }
}

.animatedContainer {
    position: absolute;
    top: -10%;
    z-index: -1;
}

.animatedText {
    font-size: 100px;
    line-height: 80%;
    text-transform: uppercase;
    padding: 0;
    animation: loopText 60s infinite linear;
    color: #11161f;
    pointer-events: none;
    z-index: -1;
}

.animatedRow {
    display: flex;
    white-space: nowrap;
}
.animatedRow:nth-child(even) .animatedText {
    animation: loopText2 60s infinite linear;
}

@keyframes loopText {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes loopText2 {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}
