.headerStage {
    color: rgba(30, 39, 55, 1);
    margin-right: 11px;
    text-decoration: none;
    align-content: center;
    text-align: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: #ff7f47;
}
.verificationHeader {
    display: flex;
    align-items: center;
    position: fixed;
    height: 80px;
    width: 100%;
    padding: 16px;
    background-color: #1e2737;
    color: #ede9e3;
    font-size: 21px;
    line-height: 25px;
}
.informationWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    padding: 112px 16px 32px 16px;
    min-height: 100%;
    background-color: rgba(237, 233, 227, 1);
}
.verificationItem {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
        margin-bottom: 32px;
        font-size: 36px;
        line-height: 41px;
    }
}

.uploadPhotoBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
}

.photo {
    width: 114px;
    height: 114px;
    background: rgba(212, 207, 199, 1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        display: none;
    }
}
.uploadButtonBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formContainer {
    height: 100%;
}
.bootstrapContainer {
    padding-right: 0;
    padding-left: 0;
    height: 100%;
    form {
        height: 100%;
    }
}
.userPageInput {
    color: rgba(0, 0, 0, 0.4) !important;
    background-color: #d4cfc7 !important;
    padding: 20px 24px !important;
    border-radius: 8px !important;
    border: none !important;
    margin-bottom: 8px !important;
    font-size: 16px !important;
    line-height: 19px !important;
}
.userPageInput:focus {
    background-color: #ffffff !important;
    box-shadow: none !important;
    color: #000000 !important;
}
.deliveryText {
    font-family: 'GT Pressura, monospace';
    margin-bottom: 16px;
    margin-top: 32px;
    font-size: 12px;
    line-height: 12px;
}

.buttonBox {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
}
@media ((min-width: 1024px)) {
    .buttonBox {
        margin-top: 0px;
        button {
            width: 94px;
        }
    }
    .verificationHeader {
        display: none;
    }
    .btnUserProfileNext {
        width: 94px;
        margin-top: 16px;
    }
    .verificationHeader {
        display: none;
    }
    .informationWrapper {
        align-items: center;
        padding: 105px 20px;
    }
    .verificationItem {
        width: 74%;

        h4 {
            margin-bottom: 48px;
        }
    }
    .uploadPhotoBox {
        display: flex;
        justify-content: flex-start;
    }
    .photo {
        width: 212px;
        height: 212px;
        margin-right: 48px;
        display: flex;
        flex-direction: column-reverse;
        p {
            opacity: 0.4;
            display: block;
            margin-top: 8px;
        }
    }
    .formContainer {
        margin-top: 48px;
    }
    .bootstrapContainer {
        form {
            height: auto;
        }
    }
    .deliveryText {
        margin-top: 76px;
    }
}
@media (min-width: 1440px) {
    .informationWrapper {
        padding: 222px 20px;
    }
}
