.container {
    background: #11161f;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
}
@media (min-width: 1024px) {
    .container:after {
        content: '';
        width: 100%;
        background: #11161f;
        height: 100%;
        position: absolute;
        right: -100%;
        pointer-events: none;
    }
}

.scrollable {
    padding-bottom: 35px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.scrollable::-moz-scrollbar-button,
.scrollable::-webkit-scrollbar-button {
    height: 0;
    width: 0;
    display: none;
}

.scrollable::-webkit-scrollbar,
.scrollable::-webkit-scrollbar-track {
    width: 4px;
    background-color: transparent;
    /* margin-bottom: 50px; */
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: #ede9e3;
    width: 4px;
    border-radius: 20px;
}
.input {
    border: 0;
    background: transparent;
    outline: none;
    font-size: 80px;
    color: var(--main-color);
    width: 100%;
    max-width: 300px;
    text-align: center;
}
.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    flex: 1;
}
.bidDescription {
    width: 100%;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.input[type='number'] {
    -moz-appearance: textfield;
}
.input::placeholder {
    color: #1e2737;
}

.actions {
    margin: auto auto 0;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 14px 24px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 64px;
    transition: all 0.3s;
    background-color: var(--main-color);
    color: #000000;
    width: 100%;
    max-width: 254px;
    margin-bottom: 16px;
}
.disabled .button {
    background: #1e2737;
    pointer-events: none;
}

.button:hover {
    background-color: var(--main-color-hover);
    color: #000000;
}
.button:active {
    background-color: var(--main-color-active);
    color: #000000;
}

.plusButtons {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    gap: 8px;
}

.buttonPlus {
    background-color: var(--sub-dark);
    color: var(--dark-text-color);
    border: 0;
    border-radius: 8px;
    padding: 13px;
    white-space: nowrap;
    width: 95px;
}
.buttonPlus:hover {
    background-color: var(--sub-dark-hover);
}
.buttonPlus:active {
    background-color: var(--sub-dark-active);
}

@media (max-width: 1023px) {
    .container {
        border-radius: 16px;
        margin-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .buttonPlus {
        width: 90px;
    }
    .input {
        font-size: 61px;
        line-height: 70px;
    }
    .wrapper {
        margin-top: 100px;
        margin-bottom: 70px;
    }
}
