.card {
    /* display: grid; */
    overflow-x: auto;
    /* grid-template-columns: minmax(339px, 1fr) minmax(339px, 1fr) minmax(
            339px,
            1fr
        ); */
    background-color: #ffffff;
    align-items: stretch;

    max-height: 500px;
    margin-bottom: 16px;

    border: 1px solid #000000;

    display: flex;
    padding: 0px 5% 0 0;
}

.imageContainerDesk {
    display: none;
}
.imageContainerMobile {
    display: block;
    max-height: 275px;
}
.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}
.buttonDetail {
    margin: 0 auto;
}
.truncate {
    position: relative;
    max-height: 116px; /* Высота, соответствующая 5 строкам текста */
    overflow: hidden;
}
.celebrity {
    max-height: 60px;
    position: relative;
}

.truncate p {
    margin: 0;
    line-height: 19px;
}

.truncate p::after {
    content: '...';
    position: absolute;
    bottom: 0;
    right: 121px;
    background-color: white;
    padding-left: 5px;
}

.truncate .learn {
    position: absolute;
    border: none;
    text-decoration: underline;
    bottom: 0;
    right: 0;
    background-color: white; /* Цвет фона, чтобы скрыть часть текста */
    padding: 0 5px; /* Отступы вокруг кнопки */
    cursor: pointer;
    padding-right: 36px;
}
.title {
    font-family: 'Aeonik';
    font-size: 27px;
    line-height: 30px;
    margin-top: 16px;
}

.paymentInfo {
    padding: 16px;
    flex: 0 0 98%;
    ul {
        padding: 0;
        margin-top: 46px;
        gap: 36px;
        height: 100%;
        display: flex;
        flex-direction: column;
        list-style-type: none;
    }
    ul li::before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #000000;
        margin-right: 11px;
    }
    li {
        display: flex;
        align-items: center;
    }
}
.paymentFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.subTitle {
    font-size: 12px;
    line-height: 12px;
    font-family: 'GT Pressura', monospace;
    text-transform: uppercase;
}
.gridContainer {
    /* display: grid;
    grid-template-columns: 1fr; */
    grid-gap: 20px;
    border-right: solid 1px black;
    padding: 16px;
    height: 500px;
    /* min-width: 339px; */
    display: flex;
    flex: 0 0 98%;
}

.lotAbout {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}
.lotTitleBox {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
}
.charityBox {
    border-right: #000000 solid 1px;
    display: flex;
    gap: 32px;
    flex-direction: column;
    padding: 16px;

    flex: 0 0 99%;
}

@media (min-width: 1024px) and (max-width: 1280px) {
}
@media (min-width: 1024px) {
    .card {
        display: grid;
        padding: 0;
        overflow-x: inherit;
        grid-template-columns: 2fr 1fr 1fr;
    }
    .lotAbout {
        grid-template-columns: 1fr 1fr;
    }
    .gridContainer {
        padding: 24px 0 24px 24px;
    }
    .paymentInfo {
        padding: 24px;
        ul {
            gap: 48px;
        }
    }
    .imageContainerDesk {
        display: block;
        max-height: 452px;
    }
    .imageContainerMobile {
        display: none;
    }
    .buttonDetail {
        margin-left: 0;
    }
    .charityBox {
        padding: 24px;
    }
}
