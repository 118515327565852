.modalInput {
    color: rgba(0, 0, 0, 0.4) !important;
    background-color: #d4cfc7 !important;
    padding: 20px 24px !important;
    border-radius: 8px !important;
    border: none !important;
    margin-bottom: 8px !important;
    font-size: 16px !important;
    line-height: 19px !important;
}

.modalInput:focus {
    background-color: #ffffff !important;
    box-shadow: none !important;
    color: #000000 !important;
}

.link {
    color: #000000;
    font-size: 16px;
    line-height: 19px;
}
