.modalInput {
    width: 24%;
    max-width: 96px;
    box-sizing: border-box;
    margin-right: 8px;
    height: 116px;
    color: rgba(0, 0, 0, 0.4) !important;
    background-color: #d4cfc7 !important;
    padding: 20px 24px !important;
    border-radius: 8px !important;
    border: none !important;

    font-size: 36px !important;
    line-height: 41px !important;
    text-align: center;
}
.modalInput:last-child {
    margin-right: 0;
}
.modalInput:focus {
    background-color: #ffffff !important;
    box-shadow: none !important;
    color: #000000 !important;
}

.resetContainer {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    width: 100%;
    box-sizing: border-box;
}
