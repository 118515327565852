.modalTextOverlay {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    visibility: hidden;
    transition:
        opacity 0.3s linear,
        visibility 0.3s linear;
}
.modalTextOverlay.show {
    opacity: 1 !important;
    visibility: visible !important;
}

.modalTextContent {
    justify-content: flex-start;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 1);
    color: #000000;
    padding: 16px;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(-80px);
    transition:
        opacity 0.3s linear,
        visibility 0.3s linear;
}

.modalTextOverlay.show .modalTextContent {
    opacity: 1;
    transform: translateY(0);
    transition:
        opacity 0.3s linear,
        visibility 0.3s linear;
}
@media (min-width: 1024px) {
    .modalTextContent {
        justify-content: space-between;
        overflow-y: hidden;
        width: 919px;
        padding: 0px 16px 48px 48px;
        height: 595px;
        border-radius: 8px;
        max-height: 95vh;
    }
}
