.desktopImage {
    padding: 0;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}
.carousel {
    padding: 0;
}

.mobileImage {
    height: 348px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    background-repeat: no-repeat;
    background-size: cover;
}
.mobileImage :global(img) {
    max-height: 100%;
    max-width: 100%;
}

.swiperNavigation {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin: 16px 0;
}

.navigationArrow:hover {
    cursor: pointer;
}

.galleryContainer {
    padding: 0;
}

@media (max-width: 1023px) {
    .galleryContainer {
        margin-top: 152px;
    }
}
