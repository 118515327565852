

.closeIcon {
    display: flex;
}
.closeIconBox {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 38px;
    height: 38px;
    background-color: #d4cfc7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;

    cursor: pointer;
}
.closeIcon img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}



@media (min-width: 1440px) {
    .closeIconBox {
        width: 48px;
        height: 48px;
    }

}

.wrapper {
    display: flex;
    gap: 60px;
}

.titleImageBox {
    display: flex;
    flex-direction: column;
    padding-top: 90px;
}

.image {
    width: 290px;
    height: 372px;
    border: 1px solid #000;
    padding: 14px 7px;
    margin-top: 32px;
}

.image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.content {
    overflow-y: auto;
    height: 595px;
    max-height: 95vh;
    width: 392px;
    padding-top: 90px;
    padding-bottom: 32px;
}
.content::-moz-scrollbar-button,
.content::-webkit-scrollbar-button {
    height: 0;
    width: 0;
    display: none;
}

.content::-webkit-scrollbar,
.content::-webkit-scrollbar-track {
    width: 0px;
    background-color: transparent;
    /* margin-bottom: 50px; */
}

.content::-webkit-scrollbar-thumb {
    background-color: transparent;
    width: 4px;
    border-radius: 20px;
}
.content:hover::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.contentTitle {
    font-size: 27px;
    margin-bottom: 8px;
}

.propsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.contentRow {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #000;
}

@media (max-width: 1023px) {
    .wrapper {
        flex-direction: column;
        gap:40px;
    }
    .titleImageBox {
        flex-direction: column-reverse;
        padding-top:64px;
    }
    .image {
        margin-top:0;
        margin-bottom: 40px;
    }
    .content {
        padding-top:0;
        width:100%;
        height:auto;
        max-height: unset;
    }
}
