.card {
    height: 500px;
    overflow: hidden;
    border: 1px solid #000000;
    border-radius: 0;
    margin-bottom: 40px;
}

.title {
    font-size: 80px;
    line-height: 92px;
    max-width: 684px;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    box-sizing: initial;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cardDescription {
    margin-top: auto;
}

.status {
}

@media (max-width: 1023px) {
    .card {
        height: auto;
        border: 0;
    }
    .title {
        font-size: 36px;
        line-height: normal;
        margin-bottom: 16px;
    }
}
