.head {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.heading {
    color: #ede9e3;
    font-size: 40px;
    line-height: 54px;
}

.subheading {
    font-size: 24px;
    line-height: 31px;
    margin-left: 32px;
    padding-left: 32px;
    position: relative;
}
.subheading.active {
    color: var(--main-color);
}
.subheading:before {
    content: '';
    background: #ede9e3;
    width: 1px;
    height: 35px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.list {
    width: 100%;
    overflow-y: auto;
    margin-top: 40px;
    max-height: calc(682px - 54px - 40px - 20px - 104px);
    height: calc(
        75vh - 54px - 40px - 20px - 104px
    ); /* container height -  heading height - margin - wrapper padding - bottom container height */
}

.list::-moz-scrollbar-button,
.list::-webkit-scrollbar-button {
    height: 0;
    width: 0;
    display: none;
}

.list::-webkit-scrollbar,
.list::-webkit-scrollbar-track {
    width: 4px;
    background-color: transparent;
    /* margin-bottom: 50px; */
}

.list::-webkit-scrollbar-thumb {
    background-color: #ede9e3;
    width: 4px;
    border-radius: 20px;
}
.list.noBids::-webkit-scrollbar-thumb {
    background-color:transparent;
}
.bidRow {
    display: flex;
    padding: 24px 30px 30px 24px;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 8px;
    align-items: center;
}

.bidIndex {
    color: #1e2737;
    background: #fff;
    height: 35px;
    min-width: 35px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 19px;
}
.bidRow:nth-child(n + 2) {
    color: #ede9e3;
}
.bidRow:nth-child(n + 2) .bidIndex {
    background: #ede9e3;
}

.bidInfo {
    margin-left: 24px;
    font-size: 12px;
    font-family: 'GT Pressura', monospace;
}

.bidAmount {
    font-size: 36px;
    line-height: 41px;
    margin-left: auto;
}

.bidRow.outbid {
    border-color: #fff;
}

.bidRow.top {
    border-color: var(--main-color);
    color: var(--main-color);
}
.bidRow.top .bidIndex {
    color: #1e2737;
    background: var(--main-color);
}

.rowPlaceholder {
    display: flex;
    padding: 32px 24px;
}
.rowPlaceholderAvatar {
    width: 35px;
    height: 35px;
    background-color: #11161f;
    border-radius: 35px;
    margin-right: 24px;
}
.rowPlaceholderContent {
    display: flex;
    flex-direction: column;
}
.rowPlaceholderText {
    width: 82px;
    background-color: #11161f;
    height: 11px;
}
.rowPlaceholderText:last-child {
    width: 152px;
    margin-top: 8px;
}

.noBidsText {
    display: none;
}
@media (max-width: 1023px) {
    .heading {
        font-size: 24px;
        line-height: 31px;
    }
    .subheading {
        font-size: 15px;
        line-height: 19px;
        margin-left: 24px;
        padding-left: 24px;
    }
    .subheading:before {
        height: 25px;
    }
    .bidAmount {
        font-size: 21px;
        line-height: 25px;
    }
    .bidRow {
        padding: 16px;
    }
    .bidInfo {
        margin-left: 16px;
    }
    .rowPlaceholder {
        padding: 16px;
    }
    .rowPlaceholder:nth-child(n + 5) {
        display: none;
    }
    .noBidsText {
        display: block;
        padding-left:16px;
        padding-top:22px;
    }

}
