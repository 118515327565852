.wrapper {
    margin-top: 136px;
    padding: 0;
    padding-bottom: 40px;
}
.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.swiperNavigation {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin: 16px 16px 16px 0;
}

.navigationArrow:hover {
    cursor: pointer;
}
