.toggleIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggleCard {
    background-color: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #000;
}

.toggleHeader {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    background-color: transparent;
    align-items: center;
}
.toggleHeader p {
    padding-right:10%;
}

.toggleBody {
    font-size: 21px;
    line-height: 120%;
    background-color: transparent;
    padding-right: 10%;
}

.toggleCard.active .toggleHeader,
.toggleCard.active .toggleBody {
    background-color: #EDE9E3;
}
