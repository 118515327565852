.imageContainer {
    height: 213px;
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    width:50%;
}
.imageContainer img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.title {
    font-size: 36px;
    line-height: 41px;
    margin-top:40px;
}
