.container {
    display: flex;
    align-items: center;
    flex-direction: column;

    text-align: center;
    max-width: 448px;
    margin: 50px auto;
}

.container h4 {
    font-size: 36px;
    line-height: 41px;
    margin-bottom: 8px;
}
