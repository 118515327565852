.animatedText {
    font-size: 270px;
    line-height: 90%;
    text-transform: uppercase;
    padding: 0;
    animation: loopText 60s infinite linear;
    color: #11161f;
    pointer-events: none;
}
.container {
    top: -20%;
    width: 100vw;
    position: absolute;
    left: 0;
    overflow: hidden;
    font-family: sans-serif;
}
.animatedRow {
    display: flex;
    white-space: nowrap;
}
.animatedRow:nth-child(even) .animatedText {
    animation: loopText2 60s infinite linear;
}

.winnerText {
    font-size: 80px;
    color: var(--main-color);
    z-index: 9;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes loopText {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes loopText2 {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}
@media (max-width: 1023px) {
    .winnerText {
        font-size: 61px;
    }
}
