.closeIcon {
    display: flex;
    justify-content: flex-end;
}
.btnLogin {
    border-radius: 64px;
    padding: 16px 24px;
    background-color: var(--main-color);
    color: #1e2737;
    font-size: 16px;
    line-height: 19px;
    border: none;
    width: 100%;
    margin-bottom: 16px;
}

.secureWrapper {
    padding: 16px 16px 32px 16px;
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}

.wrapperSecure h4 {
    font-size: 36px;
    line-height: 41px;
    font-weight: 400;
    margin-bottom: 16px;
    margin-top: 0px;
}
.wrapperSecure span {
    align-self: start;
}
.secureCard {
    align-items: center;
    border-radius: 13px;
    background: rgba(255, 255, 255, 1);
    padding: 15px;
    display: flex;
    font-size: 20px;
    line-height: 24px;
}
.secureCardIcon {
    margin-right: 5px;
}

.gridContainerSecure {
    display: flex;
    gap: 11px;
    margin-top: 32px;
    flex-direction: column;
}

@media (min-width: 1024px) {
    .closeIcon {
        display: none;
    }
    .wrapperSecure span {
        display: none;
    }
    .wrapperSecure h4 {
        margin-bottom: 8px;
    }
    .modalFormBox {
        margin-top: 0px;
    }

    .gridContainerSecure {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 14px;
        margin-top: 16px;
    }
    .secureWrapper {
        overflow-y: hidden;
        padding: 16px 16px 32px 16px;
    }
}
@media (min-width: 1440px) {
    .gridContainerSecure {
        margin-top: 32px;
    }
    .wrapperSecure h4 {
        margin-bottom: 16px;
    }
    .secureWrapper {
        padding: 32px 16px;
    }
}
