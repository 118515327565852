.card {
    display: flex;
    background-color: #1e2737;
    align-items: stretch;
    padding: 24px;
    color: #fff;
    margin-bottom: 16px;
    gap: 24px;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
}
.imageContent {
    display: flex;
    flex-direction: column;
}
.bid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    gap: 16px;
}
.divider {
    width: 1px;
    height: 48px;
    background-color: #d4cfc7;
    margin: 0 48px;
}
.title {
    width: 531px;
    font-family: 'Aeonik';
    font-size: 27px;
    line-height: 30px;
    margin-top: 8px;
}

.bidInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.bidAmount {
    color: #ede9e3;
    font-size: 47px;
    line-height: 53px;
    font-family: 'Aeonik';
    margin-top: 4px;
}
.subTitle {
    font-family: 'GT Pressura', monospace;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
}
.bidAmountBox {
    font-family: 'GT Pressura', monospace;
    color: #ede9e3;

    p {
        font-size: 12px;
        line-height: 12px;
    }
}
.highestBidder {
    font-size: 27px;
    line-height: 30px;
    color: #ff7f47;
}
.highestBidder.highest {
    color: #ff7f47; /* Стили для случая, когда ставка выигрывает */
}

.highestBidder.outBid {
    color: #ede9e3; /* Стили для случая, когда ставка проигрывает */
}
.buttons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    height: 51px;
}
.cardImage {
    width: 145px;
    height: 213px;
}
@media (max-width:1199px) and (min-width:1024px) {
    .bid {
        margin-top:30px;
    }
}
@media (max-width: 1023px) {
    .card,
    .bid {
        gap: 32px;
        flex-direction: column;
        align-items: flex-start;
    }
    .content {
        gap: 72px;
    }
    .title {
        width: auto;
    }
    .buttons {
        width: 100%;
        justify-content: space-between;
    }

    .button {
        width: 147px;
    }
    .highestBidder {
        font-size: 16px;
        line-height: 19px;
    }
    .bidAmount {
        font-size: 27px;
        line-height: 30px;
    }
    .divider {
        margin-left: 24px;
        margin-right: 24px;
    }
}
