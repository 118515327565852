.checkboxContainer {
    width: 100%;
}

.checkboxItem {
    margin-bottom: 16px;
    font-size: 21px;
    line-height: 25px;
    font-family: 'Aeonik' !important;
    color: #000000;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    label {
        margin-right: 8px;
        text-align: left;
    }

    input {
        width: 31px;
        height: 31px;
        background-color: #d4cfc7;
    }
}

.checkboxItem input[type='checkbox']:checked {
    border-color: #1e2737;
    background-color: #1e2737;
    background-image: url('../../../../../public/checked.svg');
    background-size: 16px 11px;
    background-position: center;
    background-repeat: no-repeat;
}
.formCheckbox {
    min-height: auto;
}
.linkText {
    text-decoration: underline;
    color: #000000;
    cursor: pointer;
}

.linkText:hover {
    color: #1e2737;
}

.divider {
    border-top: 1px solid #d4cfc7;
    margin: 24px 0;
}
