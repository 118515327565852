.impactCardsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin-bottom: 16px;
}
@media (min-width: 1024px) {
    .impactCardsWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
    }
}
