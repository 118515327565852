.titleContainer {
    margin-bottom: 40px;
    max-width: 684px;
}
.content {
    margin-top:88px;
    max-width: 447px;
}

.videoContainer {
    margin-top:88px
}
.descriptionWrapper {
    position: sticky;
    top: 72px;
    height: max-content;
    padding: 25px;
}

.description {
    margin:32px 0;
}

.videoWrapper {
    height:291px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
}
.videoWrapper:hover {
    cursor: pointer;
}


@media (max-width:1023px) {
    .descriptionWrapper {
        position: static;
        height:auto;
        padding:16px;
    }
    .content {
        margin-top:52px;
    }
}
