.progressContainer {
    height: 78px;
    border-radius: 8px;
    background-color: #ffffff;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'GT Pressura', monospace;
    /* margin-top: 44px; */
    margin-bottom: 10px;
}

.filename {
    font-family: 'GT Pressura', monospace;
    font-size: 16px;
    color: #000000;
    line-height: 19px;
}

.progressBar {
    width: 80%;
    height: 8px;
    background-color: #e8e3da;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 8px;
}

.progressFill {
    height: 100%;
    background-color: #212228;
    border-radius: 5px;
    transition: width 0.3s ease;
}

.percentage {
    font-size: 12px;
    color: #000000;
    font-family: 'GT Pressura', monospace;
}

.closeButton {
    width: 36px;
    height: 36px;
    background-color: #212228;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 32px;
    cursor: pointer;
}
