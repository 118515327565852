.link {
    white-space: nowrap;
    font-size: 80px;
    text-decoration: none;
    color: var(--neutral-dark);
    margin-right: 40px;
    letter-spacing: -0.03em;
}
.link sup {
    position: relative;
    display: inline-block;
    font-size: 12px;
    top: -60px;
    color: #000;
    letter-spacing: normal;
    font-family: 'GT Pressura', sans-serif;
}

.active {
    color: #000;
}

.container {
    margin-bottom: 10px;
    margin-top: 20px;
}

@media (max-width: 1023px) {
    .container {
        display: flex;
        overflow-x: auto;
    }
    .link {
        font-size: 47px;
        line-height: 60px;
        padding-left: 15px;
        position: relative;
        margin-right: 24px;
    }
    .link sup {
        top: 10px;
        position: absolute;
        left: 0;
    }
}
@media (min-width: 1024px) and (max-width: 1280px) {
    .container {
        margin-bottom: 100px;
    }
}
