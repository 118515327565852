.button {
    width: 48px;
    height: 48px;
    cursor: pointer;
    border-radius: 50%;
    background: #11161f;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
}

@media (max-width: 1023px) {
    .button {
        width: 36px;
        height: 36px;
        margin-bottom: 20px;
    }
    .button svg {
        width: 18px;
    }
}
