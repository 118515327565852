.modalOverlay {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition:
        opacity 0.3s linear,
        visibility 0.3s linear;
}
.modalOverlay.show {
    opacity: 1;
    visibility: visible;
}
.modalContent {
    background-color: #ede9e3;
    color: #000000;
    padding: 16px 16px;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    transform: translateY(-80px);
    transition:
        opacity 0.3s linear,
        visibility 0.3s linear;

    h4 {
        font-size: 36px;
        line-height: 41px;
        font-weight: 400;
    }
}
.modalOverlay.show .modalContent {
    opacity: 1;
    transform: translateY(0);
    transition:
        opacity 0.3s linear,
        visibility 0.3s linear;
}

@media (min-width: 1024px) {
    .modalContent {
        border-radius: 16px;
        padding: 22px;
        width: 40%;
        min-height: 470px;
        height: fit-content;
    }
}
@media (min-width: 1440px) {
    .modalContent {
        min-height: 570px;
        padding: 32px;
        width: 33%;
    }
}
