.contentWrapper {
    display: flex;
    flex-direction: column;
}
.itemBlock {
    display: flex;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 32px;
}
.icon {
    margin-right: 16px;
}
@media (min-width: 1024px) {
    .itemBlock {
        margin-bottom: 16px;
    }
}
@media (min-width: 1440px) {
    .itemBlock {
        margin-bottom: 32px;
    }
}
