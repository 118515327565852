.container {
    height: 291px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
}
.container:hover {
    cursor: pointer;
}
