.head {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.heading {
    color: #ede9e3;
    font-size: 40px;
    line-height: 54px;
}

@media (max-width: 1023px) {
    .heading {
        font-size: 36px;
        line-height: 41px;
        margin-top: 40px;
    }
    .head {
        flex-direction: column;
        align-items: flex-start;
        margin-top: -36px;
    }
}
