.heroSection {
    background-image: url('/src/assets/images/hero.jpg');
    background-size: cover;
    background-position: center;
    height: 800px;
    display: flex;
    align-items: center;
    color: white;
}
.heroSection h1 {
    color: var(--main-color);
    font-size: 80px;
    text-align: center;
}

@media (max-width: 1023px) {
    .heroSection {
        height: 660px;
        background-image: url('/src/assets/images/hero-mobile.jpg');
    }
    .heroSection h1 {
        font-size: 47px;
    }
}
