.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #000000;
    padding: 20px;
    background-color: #ffffff;
    color: #000000;
}

.charityInfo {
    text-align: left;
    width: 100%;
}

.charityLabel {
    font-size: 12px;
    font-family: 'GT Pressura', monospace;
    margin-bottom: 16px;
}

.charityName {
    font-size: 27px;
    line-height: 30px;
    color: #000000;
    margin: 0;
}

.charityDescription {
    font-size: 14px;

    margin-top: 10px;
}

.racketImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-self: flex-end;
}

.titleImageWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: start;
    justify-items: start;
}
.imageWrapper {
    width: 97px;
    height: 145px;
    order: 1;
}

.charityInfo {
    margin-top: 32px;
    order: 2;
}
.donationInfo {
    text-align: left;
    width: 100%;
    margin-top: 32px;
    font-size: 12px;

}

.donationLabel {
    font-size: 12px;
    color: #000;
    margin-bottom: 5px;
    font-family: 'GT Pressura', monospace;
}

.donationAmount {
    font-size: 47px;
    line-height: 53px;
    color: #000000;
    margin: 0;
}

.buttons {
    display: flex;
    gap: 10px;

    margin-top: 56px;
    flex-direction: column;
    width: 100%;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #e0e0e0;
    color: #000000;
    font-size: 14px;
    cursor: pointer;
}

.button:hover {
    background-color: #cccccc;
}
.donationBox {
    width: 100%;
}
@media (min-width: 1024px) {
    .donationAmount {
        font-size: 80px;
        line-height: 91px;
    }
    .card {
        height: 500px;
    }
    .donationBox {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .imageWrapper {
        width: 130px;
        height: 193px;

        order: 2;
    }

    .charityInfo {
        margin-top: 0;
        order: 1;
    }
    .titleImageWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        justify-items: end;
        align-items: center;
    }
    .buttons {
        margin-top: 20px;
        flex-direction: row;
    }
    .donationInfo {
        margin-top: 0px;
    }
}
