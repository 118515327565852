.btnVerificationNext {
    width: 100%;
    background-color: rgba(255, 127, 71, 1);
    border-radius: 64px;
    padding: 16px 24px;
    color: rgba(30, 39, 55, 1);
    font-size: 16px;
    line-height: 19px;
    border: none;
}
.verificationHeader {
    display: flex;
    align-items: center;
    position: fixed;
    height: 80px;
    width: 100%;
    padding: 16px;
    background-color: #1e2737;
    color: #ede9e3;
    font-size: 21px;
    line-height: 25px;
}
.btnVerificationNext:hover {
    opacity: 0.9;
}
.btnVerificationNext:active {
    background: linear-gradient(0deg, #ff7f47, #ff7f47),
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.reviewsBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.titleReviewing {
    margin-bottom: 32px;
    font-size: 41px;
    line-height: 45px;
}
.reviewsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    padding: 112px 16px 32px 16px;
    min-height: 100vh;
    background-color: rgba(237, 233, 227, 1);
}
.headerReviewStage {
    color: rgba(30, 39, 55, 1);
    margin-right: 11px;
    text-decoration: none;
    align-content: center;
    text-align: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: #11161f;
}
.reviewsButtonWrapper {
    width: 100%;
}
.reviewingSubtitle {
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    form {
        width: 100%;
    }
}
@media (min-width: 1024px) {
    .reviewsButtonWrapper {
        display: flex;
        justify-content: flex-start;
        margin-top: 32px;
        margin-top: 48px;
        button {
            width: fit-content;
        }
    }
    .reviewingSubtitle {
        font-size: 21px;
        line-height: 28px;
        align-items: center;
    }
    .reviewsBox {
        width: 67%;
        height: auto;

        justify-content: center;
    }

    .reviewsContainer {
        margin-top: 0px;
        padding: 0;
    }
    .btnVerificationNext {
        width: fit-content;
    }
    .verificationHeader {
        display: none;
    }
}

@media (min-width: 1280px) {
    .reviewingSubtitle {
        font-size: 44px;
        line-height: 51px;
    }
    .titleReviewing {
        margin-bottom: 48px;
        font-size: 51px;
        line-height: 60px;
    }
}
@media (min-width: 1440px) {
    .reviewingSubtitle {
        font-size: 36px;
        line-height: 41px;
    }
    .titleReviewing {
        margin-bottom: 48px;
        font-size: 61px;
        line-height: 70px;
    }
}
