.closeIcon {
    display: flex;
}
.closeIconBox {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 38px;
    height: 38px;
    background-color: #d4cfc7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    z-index: 9;

    cursor: pointer;
}
.closeIcon img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

@media (min-width: 1024px) {
    .closeIconBox {
        width: 48px;
        height: 48px;
    }
}

.wrapper video {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media (max-width: 1023px) {
    .wrapper video {
        object-fit: contain;
    }
}
