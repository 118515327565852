.status {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 56px;
    padding-top: 24px;
    position: relative;
    margin-top: auto;
    padding-bottom: 16px;
}

@media (max-width: 1023px) {
    .status {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 24px;
        grid-column-gap: 24px;
    }
}
