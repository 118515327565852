.heroUserSection {
    background-color: #d4cfc7;
    height: 800px;
    display: flex;
    align-items: center;
    color: #000000;
}
.heroUserSection h1 {
    color: #000000;
    font-size: 80px;
    text-align: center;
}
.titleImageBox {
    margin-top: 48px;
    display: flex;
    gap: 32px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.imageBox {
    width: 212px;
    height: 212px;
    border-radius: 50%;
    background-image: url('/src/assets/images/mona_profile.png');
    background-size: cover;
    background-position: center;
    position: relative;
}
.verified {
    position: absolute;
    right: 0;
    width: 56px;
    height: 56px;
    background-image: url('/src/assets/images/verified.svg');
}
.verifyText {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}
.imageButton {
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
}

@media (max-width: 1023px) {
    .verifyText {
        margin-top: 36px;
    }
    .heroUserSection {
        height: 660px;
    }
    .heroUserSection h1 {
        font-size: 47px;
    }
}
