.headerStage {
    color: rgba(30, 39, 55, 1);
    margin-right: 11px;
    text-decoration: none;
    align-content: center;
    text-align: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: #ff7f47;
}
.checkboxContainer {
    width: 100%;
}
.dividerNotification {
    margin-top: 24px;
    margin-bottom: 24px;
}
.notificationUserWrapper {
    display: flex;
    align-items: flex-start;

    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    padding-top: 112px;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    min-height: 100%;
    background-color: rgba(237, 233, 227, 1);
}
.InformationItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
        margin-bottom: 32px;
        font-size: 36px;
        line-height: 41px;
    }
}
.backIconMobile {
    cursor: pointer;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    background-color: #d4cfc7;
    background-image: url('../../../../public/black_back_arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    outline: none;
    box-shadow: none;
    margin-bottom: 40px;
}

.btnUserPage {
    border-radius: 64px;
    padding: 16px 24px;
    color: rgba(30, 39, 55, 1);
    font-size: 16px;
    line-height: 19px;
    border: none;
}

.btnUserPage:hover {
    opacity: 0.9;
}
.active:active {
    background: linear-gradient(0deg, #ff7f47, #ff7f47),
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.emailNotificationContainer {
    padding-right: 0;
    padding-left: 0;
    height: 100%;
    form {
        height: 100%;
    }
}
.switchItem {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    input[type='checkbox'] {
        background-color: #d4cfc7 !important;
        border-color: #d4cfc7 !important;
        width: 43px;
        height: 24px;
        --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
    }
}

.switchItem input[type='checkbox']:checked {
    background-color: #000000 !important;
    border-color: #000000 !important;
}

.labelBlock {
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: flex-start;
    font-size: 25px;
    line-height: 27px;
    .linkText {
        font-size: 16px;
        line-height: 19px;
    }
}
.deliveryText {
    font-family: 'GT Pressura, monospace';
    margin-bottom: 16px;
    margin-top: 32px;
    font-size: 12px;
    line-height: 12px;
}
.active {
    background-color: rgba(255, 127, 71, 1);
}
.buttonBox {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}
.resizeNotificationButton {
    width: 100%;
}
.formContainer {
    margin-top: 32px;
}
.labelBlockNotification {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
@media ((min-width: 1024px)) {
    .resizeNotificationButton {
        width: 178px;
    }
    .backIconMobile {
        display: none;
    }

    .notificationUserWrapper {
        justify-content: center;
        align-items: center;
        padding-top: 120px;
        padding-bottom: 74px;
    }
    .InformationItem {
        width: 74%;

        h4 {
            margin-bottom: 48px;
        }
    }
    .uploadPhotoBox {
        display: flex;
        justify-content: flex-start;
    }
    .photo {
        width: 212px;
        height: 212px;
        margin-right: 48px;
        display: flex;
        flex-direction: column-reverse;
        p {
            opacity: 0.4;
            display: block;
            margin-top: 8px;
        }
    }
    .formContainer {
        margin-top: 48px;
    }
    .emailNotificationContainer {
        form {
            height: auto;
        }
    }
    .deliveryText {
        margin-top: 76px;
    }
}
@media (min-width: 1440px) {
}
