.container h1,
.container h2 {
    font-size: 50px;
    line-height: 91px;
    letter-spacing: -0.03em;
    margin-bottom: 16px;
}
.container {
    max-width: 996px;
}
.container div {
    font-size:33px;
    line-height: 44px;
    margin-bottom: 47px;
}


@media (max-width: 1023px) {
    .container h1,
    .container h2 {
        font-size: 36px;
        line-height: 54px;
    }
    .container div {
        font-size:21px;
        line-height: 27px;
        margin-bottom: 27px;
    }
}
