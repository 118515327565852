.container {
    position: fixed;
    padding: 12px;
    margin-bottom: 12px;
}
.container svg {
    flex-shrink: 0;
    margin-right: 16px;
}
.toast {
    border: 1px solid #fb1700;
    background: #ffbbb5;
    font-size: 16px;
    line-height: 19px;
    width: 250px;
    box-shadow: none;
    border-radius: 8px;
}
