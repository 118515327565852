.btnVerificationNext {
    width: 100%;
    background-color: rgba(255, 127, 71, 1);
    border-radius: 64px;
    padding: 16px 24px;
    color: rgba(30, 39, 55, 1);
    font-size: 16px;
    line-height: 19px;
    border: none;
}
.verificationHeader {
    display: flex;
    align-items: center;
    position: fixed;
    height: 80px;
    width: 100%;
    padding: 16px;
    background-color: #1e2737;
    color: #ede9e3;
    font-size: 21px;
    line-height: 25px;
}
.btnVerificationNext:hover {
    opacity: 0.9;
}
.btnVerificationNext:active {
    background: linear-gradient(0deg, #ff7f47, #ff7f47),
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.bankBox {
    width: 100%;
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.titleVerification {
    margin-bottom: 32px;
    font-size: 36px;
    line-height: 41px;
}
.bankContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 112px 16px 32px 16px;
    min-height: 100vh;
    background-color: rgba(237, 233, 227, 1);
}
.headerStage {
    color: rgba(30, 39, 55, 1);
    margin-right: 11px;
    text-decoration: none;
    align-content: center;
    text-align: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: #ff7f47;
}
.verificationButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    button {
        width: 100%;
    }
}
.thirdPartyBox {
    border-radius: 8px;
    background-color: #d4cfc7;
    min-height: 345px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width: 1024px) {
    .verificationButtonWrapper {
        button {
            width: 94px;
        }
        margin-top: 48px;
    }
    .thirdPartyBox {
        min-height: 345px;
        height: 47%;
    }
    .bankBox {
        width: 67%;
        height: 100%;
        max-height: 475px;
        justify-content: center;
    }
    .titleVerification {
        margin-bottom: 48px;
        font-size: 36px;
        line-height: 41px;
    }
    .bankContainer {
        margin-top: 0px;
        padding: 0;
    }
    .btnVerificationNext {
        width: 94px;
    }
    .verificationHeader {
        display: none;
    }
}
