.link {
    color: #000000;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Aeonik';
}

.closeIcon {
    display: flex;
    justify-content: flex-end;
}
.btnLogin {
    border-radius: 64px;
    padding: 16px 24px;
    background-color: var(--main-color);
    color: #1e2737;
    font-size: 16px;
    line-height: 19px;
    border: none;
    width: 100%;
    margin-bottom: 16px;
}
.linkForgotBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 32px;
}
.loginBox {
    display: flex;
    flex-direction: column;
}
.getStartedWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.loginBox .link {
    align-self: center;
}
.wrapperTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.wrapperStarted h4 {
    font-size: 36px;
    line-height: 41px;
    font-weight: 400;
    margin-bottom: 32px;
    margin-top: 8px;
}
.wrapperStarted span {
    align-self: start;
}

.linkForgotBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 32px;
    margin-top: 8px;
}
.modalFormBoxGetStarted {
    margin-top: 32px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
}
.footerTextGetStarted {
    font-size: 12px;
    line-height: 12px;
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 22px;

    font-family: 'GT Pressura', monospace;
}
@media (min-width: 1024px) {
    .getStartedWrapper,
    form {
        min-height: 470px;
    }
    .closeIcon {
        display: none;
    }
    .wrapperStarted span {
        display: none;
    }
    .modalFormBoxGetStarted {
        justify-content: space-between;
    }

    .wrapperStarted h4 {
        margin-top: 0px;
    }

    .footerTextGetStarted {
        margin-top: 8px;
    }
}
@media (min-width: 1440px) {
    .getStartedWrapper,
    form {
        min-height: 570px;
    }
    .wrapperStarted h4 {
        margin-top: 0px;
    }
}
