.image {
    height: 694px;
    background-position: center;
    background-size: cover;
}

@media (max-width: 1439px) {
    .image {
        height: 494px;
    }
}

@media (max-width: 1023px) {
    .image {
        height: 442px;
    }
}
