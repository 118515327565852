.headerStage {
    color: rgba(30, 39, 55, 1);
    margin-right: 11px;
    text-decoration: none;
    align-content: center;
    text-align: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: #ff7f47;
}

.passwordUserWrapper {
    display: flex;
    align-items: flex-start;

    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    padding-top: 112px;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    min-height: 100%;
    background-color: rgba(237, 233, 227, 1);
}
.InformationItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
        margin-bottom: 32px;
        font-size: 36px;
        line-height: 41px;
    }
}
.backIconMobile {
    cursor: pointer;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    background-color: #d4cfc7;
    background-image: url('../../../../public/black_back_arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    outline: none;
    box-shadow: none;
    margin-bottom: 40px;
}
.uploadPhotoBox {
    display: flex;
    width: 100%;
    gap: 46px;
    justify-content: flex-start;
    margin-bottom: 16px;
}

.photo {
    width: 114px;
    height: 114px;
    background: rgba(212, 207, 199, 1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        display: none;
    }
}
.uploadButtonBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 183px;
    gap: 12px;
}
.btnUserPage {
    border-radius: 64px;
    padding: 16px 24px;
    color: rgba(30, 39, 55, 1);
    font-size: 16px;
    line-height: 19px;
    border: none;
}
.btnUploadPhoto {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
}
.btnDelPhoto {
    width: 100%;
    background-color: rgba(212, 207, 199, 1);
}

.btnUserPage:hover {
    opacity: 0.9;
}
.active:active {
    background: linear-gradient(0deg, #ff7f47, #ff7f47),
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.informationUserContainer {
    padding-right: 0;
    padding-left: 0;
    height: 100%;
    form {
        height: 100%;
    }
}
.userPageInput {
    color: rgba(0, 0, 0, 0.4) !important;
    background-color: #d4cfc7 !important;
    padding: 20px 24px !important;
    border-radius: 8px !important;
    border: none;
    margin-bottom: 8px !important;
    font-size: 16px !important;
    line-height: 19px !important;
}
.userPageInput:focus {
    background-color: #ffffff !important;
    box-shadow: none !important;
    color: #000000 !important;
}
.dropdownMenuError {
    border: 1px solid #b40000;
}
.deliveryText {
    font-family: 'GT Pressura, monospace';
    margin-bottom: 16px;
    margin-top: 32px;
    font-size: 12px;
    line-height: 12px;
}
.active {
    background-color: rgba(255, 127, 71, 1);
}
.buttonBox {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}

.formContainer {
    margin-top: 32px;
}
@media ((min-width: 1024px)) {
    .buttonBox {
        button {
            width: fit-content;
        }
    }
    .backIconMobile {
        display: none;
    }
    .btnUserProfileNext {
        width: fit-content;
        margin-top: 16px;
    }

    .passwordUserWrapper {
        justify-content: center;
        align-items: center;
        padding-top: 120px;
        padding-bottom: 74px;
    }
    .InformationItem {
        width: 74%;

        h4 {
            margin-bottom: 48px;
        }
    }
    .uploadPhotoBox {
        display: flex;
        justify-content: flex-start;
    }
    .photo {
        width: 212px;
        height: 212px;
        margin-right: 48px;
        display: flex;
        flex-direction: column-reverse;
        p {
            opacity: 0.4;
            display: block;
            margin-top: 8px;
        }
    }
    .formContainer {
        margin-top: 48px;
    }
    .informationUserContainer {
        form {
            height: auto;
        }
    }
    .deliveryText {
        margin-top: 76px;
    }
}
