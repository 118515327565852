.container {
    padding-top: 48px;
    padding-bottom: 100px;
    /* max-width: 100%; */
}
.titleContainer {
    max-width: 684px;
    margin-bottom: 48px;
}
.description {
    margin-top: 78px;
}

@media (max-width: 1023px) {
    .titleContainer {
        margin-bottom: 16px;
    }
    .description {
        margin-top: 40px;
    }
}
