/* стили кнопок */
.btn {
    border-radius: 64px;
    padding: 16px 24px;
    background-color: var(--main-color);
    color: #1e2737;
    font-size: 16px;
    line-height: 19px;
    border: none;
    width: 100%;
    margin-top: 16px;
    font-family: inherit;
    transition: background-color 0.3s ease;
}
.btn:hover {
    opacity: 0.9;
}
.btn:active {
    background: linear-gradient(0deg, #ff7f47, #ff7f47),
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.btnGrey {
    background-color: #d4cfc7;
}

.btnGrey:hover {
    background: linear-gradient(
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.1)
        ),
        #ede9e3;
}

.btnGrey:active {
    background: rgba(0, 0, 0, 0.1);
}
.disabled {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
}
