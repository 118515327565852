.wrapper {
    padding-top: 169px;
    padding-bottom: 169px;
    background-color: #f5f2ef;
}
.wrapper.darker {
    background-color: #d4cfc7;
}

.wrapper p {
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 20px;
}

.wrapper h3 {
    font-size: 27px;
    line-height: 31px;
    margin: 24px 0;
}

@media (max-width: 1023px) {
    .wrapper {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .wrapper p {
        font-size: 16px;
        line-height: 19px;
    }
}
