.form {
    margin-top: 10vh;
}
.formLabel {
    font-family: 'GT Pressura', monospace;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 12px;
}

.form input {
    width: 100%;
    border: 0;
    outline: none;
    background: #11161f;
    color: #fff;
    height: 59px;
    border-radius: 8px;
    margin-bottom: 8px;
    font-size: 16px;
    padding-left: 24px;
    padding-right: 24px;
}

.half {
    display: flex;
}

.half input:first-child {
    margin-right: 8px;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
}
.footer div {
    width: 60%;
}

@media (max-width: 1023px) {
    .footer {
        flex-direction: column;
    }
    .footer div {
        width: 100%;
        margin-bottom: 36px;
    }
    .footer button {
        width: 100%;
    }
}
