.container {
    background: #1e2737;
    border-radius: 8px;
    color: #ede9e3;
    padding: 16px;
    margin-top:40px;
}
.title {
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 24px;
}

.infoRow {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-family: 'GT Pressura', monospace;
}
.infoRow + .infoRow {
    margin-top: 16px;
}

.infoRow div {
    font-family: inherit;
}
