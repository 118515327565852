.modalFormBox {
    bottom: 0;
}
.link {
    color: #000000;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Aeonik';
}
.linkBox {
    align-self: center;
}
.success h4 {
    margin-top: 32px;
    margin-bottom: 8px;
}
.success {
    margin-top: 32px;
}
@media (min-width: 1024px) {
    .success {
        margin-top: 0;
    }
    .footerText {
        margin-top: 64px;
    }
}
