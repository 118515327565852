.content {
    margin-top: 10vh;
}

.block {
    background: #11161f;
    padding: 16px;
    border-radius: 16px;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 24px;
}
.block + .block {
    margin-left: 24px;
}
.blockTitle {
    font-size: 21px;
    line-height: 25px;
}

.blockLink {
    text-decoration: underline;
    cursor: pointer;
}
.blockHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.blockContent {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    height: 40px;
    align-items: center;
}
.address {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    box-sizing: initial;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    width: 50%;
}

.card {
    display: flex;
    align-items: center;
}
.cardType {
    width: 53px;
    margin-right: 16px;
}

.speedyBids {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.speedyBids .blockTitle {
    margin-bottom: 16px;
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .blockTitle {
        font-size: 18px;
        line-height: 22px;
    }
    .block {
        font-size: 14px;
        line-height: 17px;
    }
    .cardType {
        width: 38px;
        margin-right: 8px;
    }
}

@media (max-width: 1023px) {
    .content {
        margin-top: 48px;
    }
    .block + .block {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .speedyBids {
        align-items: flex-end;
    }
    .speedyBids .blockTitle + div {
        width: 80%;
    }
}
