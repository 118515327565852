.AreYouSureContent {
    margin-top: 24px;
    padding: 24px 16px;
    border-radius: 8px;
    border: rgba(235, 107, 94, 1) 1px solid;
    .AreYouSureContentTitle {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 21px;
        line-height: 25px;
        margin-bottom: 8px;
        p {
            margin-top: 8px;
        }
    }
}
.AreYouSureContentText {
    text-align: center;
    line-height: 19px;
    font-size: 16px;
}
