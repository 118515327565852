.modalFormBox {
    margin-top: 32px;
}
.formSign {
    height: 100%;
}
.wrapperItemSign {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.wrapperSign {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    form {
        height: 100%;
    }
}
.wrapperForgot {
    margin-top: 32px;
    p {
        margin-bottom: 32px;
        margin-top: 8px;
    }
    h4 {
        margin-top: 32px;
    }
}

@media (min-width: 1024px) {
    .wrapperItemSign {
        min-height: 470px;
    }
    .footerText {
        margin-top: 64px;
    }
    .wrapperForgot {
        margin-top: 0;
    }
    .wrapperSign {
        padding: 10px;
    }
}

@media (min-width: 1440px) {
    .wrapperItemSign {
        min-height: 570px;
    }
    .wrapperSign {
        padding: 0;
    }
}
